interface Environment {
  BASE_URL: string;
}
const devEnvironment: Environment = {
  BASE_URL: 'http://127.0.0.1:12040'
  // BASE_URL: 'http://10.6.1.101:12040'
}
const prodEnvironment: Environment = {
  BASE_URL: 'https://ca-api.themis.events'
}
const environment: Environment = process.env.NODE_ENV === 'production' ? prodEnvironment : devEnvironment

export default environment
