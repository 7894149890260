import React from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { Slide, Theme } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
export const Transition = React.forwardRef(function Transition(props: TransitionProps & {
  children?: React.ReactElement;
}, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const autoCompleteStyles = makeStyles(theme => ({
  option: {
    '&[aria-disabled="true"]': {
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }
}));
export const useStyles = makeStyles(theme => ({
  // Other styles...
  smallSwitchContainer: {
    display: 'flex',
    alignItems: 'center' // Ensures proper vertical alignment
  },
  smallSwitch: {
    transform: 'scale(0.7)',
    // Scales the Switch to 50% of its original size
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  smallLabel: {
    fontSize: '12px' // Adjust the label font size
  },
  smallTextField: {
    '& .MuiInputBase-input': {
      padding: theme.spacing(1),
      fontSize: '12px'
    },
    '& .MuiInputBase-root': {
      fontSize: '12px',
      padding: theme.spacing(1)
    },
    '& .MuiOutlinedInput-root': {
      // Add vertical alignment fix:
      fontSize: '12px',
      padding: theme.spacing(1)
    },
    '& .MuiFormLabel-root': {
      fontSize: '20px',
      padding: theme.spacing(1)
    }
  },
  dndItem: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  // Apple-style card container
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderRadius: 12,
    boxShadow: '0 1px 3px rgba(0,0,0,0.06), 0 2px 6px rgba(0,0,0,0.03)',
    backgroundColor: theme.palette.background.paper,
    '@media (max-width:600px)': {
      padding: theme.spacing(2),
      borderRadius: 10
    }
  },
  // Responsive grid styling
  responsiveGrid: {
    '& .MuiGrid-item': {
      '@media (max-width:600px)': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
      }
    }
  },
  // Apple-style input fields
  inputField: {
    '& .MuiInputBase-root': {
      borderRadius: 8,
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
      },
      '&.Mui-focused': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        boxShadow: '0 0 0 2px rgba(0, 125, 250, 0.6)'
      }
    },
    '& .MuiInputBase-input': {
      padding: '12px 16px'
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.9375rem'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.15)'
    }
  },
  // Apple-style segmented control
  segmentedControl: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    borderRadius: 8,
    padding: 2,
    '& .MuiButton-root': {
      borderRadius: 6,
      margin: 2,
      minWidth: 'auto',
      padding: '6px 16px',
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '0.875rem',
      color: theme.palette.text.secondary,
      backgroundColor: 'transparent',
      '&.Mui-selected': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)'
      }
    }
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  table: {
    width: '100%',
    overflowX: 'auto',
    '@media (max-width:600px)': {
      display: 'block',
      fontSize: '0.8rem'
    }
  },
  // Mobile table container with horizontal scroll
  responsiveTableContainer: {
    width: '100%',
    overflowX: 'auto',
    '@media (max-width:600px)': {
      marginBottom: theme.spacing(2)
    },
    '& .MuiTableCell-root': {
      '@media (max-width:600px)': {
        padding: '8px 6px'
      }
    }
  },
  // Responsive tabs styling
  responsiveTabs: {
    '& .MuiTab-root': {
      '@media (max-width:600px)': {
        minWidth: 'auto',
        maxWidth: 'none',
        padding: '8px 12px',
        fontSize: '0.75rem'
      }
    },
    '& .MuiTabs-scrollButtons.Mui-disabled': {
      opacity: 0.3
    },
    '& .MuiTabs-flexContainer': {
      '@media (max-width:600px)': {
        flexWrap: 'wrap',
        // Allow tabs to wrap to multiple lines
        justifyContent: 'flex-start'
      }
    },
    // Remove indicator in wrapped mode since it doesn't make sense with multiple lines
    '@media (max-width:600px)': {
      '& .MuiTabs-indicator': {
        display: 'none'
      },
      // Add active tab styling since we removed the indicator
      '& .Mui-selected': {
        backgroundColor: 'rgba(179, 8, 8, 0.08)',
        borderRadius: '4px',
        fontWeight: 600,
        boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
        borderBottom: '2px solid #b30808'
      }
    }
  },
  // Vertical tabs for mobile
  verticalTabsOnMobile: {
    '@media (max-width:600px)': {
      flexDirection: 'column',
      '& .MuiTabs-flexContainer': {
        flexDirection: 'column',
        flexWrap: 'nowrap' // Don't wrap in vertical mode
      },
      '& .MuiTab-root': {
        width: '100%',
        maxWidth: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
      }
    }
  },
  // Wrap tabs for mobile - grid-style layout that's fully visible
  wrappedTabs: {
    '@media (max-width:600px)': {
      '& .MuiTabs-flexContainer': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
        gap: '4px',
        width: '100%'
      },
      '& .MuiTab-root': {
        minHeight: '48px',
        maxWidth: 'none',
        width: '100%',
        margin: 0,
        padding: '8px 4px',
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        whiteSpace: 'normal',
        lineHeight: 1.2
      },
      '& .Mui-selected': {
        backgroundColor: 'rgba(179, 8, 8, 0.08) !important',
        fontWeight: '600 !important',
        borderBottom: '2px solid #b30808 !important',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12) !important'
      },
      // Hide scrollbars completely
      '& .MuiTabs-scroller': {
        overflow: 'visible !important',
        scrollbarWidth: 'none'
      },
      '& .MuiTabs-scrollButtons': {
        display: 'none !important'
      }
    }
  },
  form: {
    width: '100%',
    // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  // Apple-style primary button
  submit: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    borderRadius: 8,
    padding: '10px 20px',
    boxShadow: 'none',
    fontSize: '0.9375rem',
    fontWeight: 500,
    textTransform: 'none',
    transition: 'background-color 0.2s, transform 0.1s',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: 'none'
    },
    '&:active': {
      transform: 'scale(0.98)'
    },
    '@media (max-width:600px)': {
      width: '100%',
      margin: theme.spacing(2, 0),
      borderRadius: 10,
      padding: '12px 20px'
    }
  },
  // Class for button containers (submit, cancel, etc)
  buttonRow: {
    display: 'flex',
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0),
    '@media (max-width:600px)': {
      flexDirection: 'column',
      gap: theme.spacing(1)
    }
  },
  divisionSubmit: {
    backgroundColor: '#b30808',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: "#d32828"
    }
  },
  logoCheck: {
    maxHeight: 100,
    maxWidth: 200,
    alignSelf: "center"
  },
  register: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#0824b3',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: "#1e36b1"
    }
  },
  autoCompleteHeader: {
    marginTop: theme.spacing(2)
  },
  warning: {
    color: 'red'
  },
  headerRoot: {
    flexGrow: 1,
    paddingBottom: theme.spacing(0),
    backgroundColor: theme.palette.background.default,
    top: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
    '@media (min-width:600px)': {
      top: '30px'
    }
  },
  headerLogo: {
    display: 'block',
    height: 'auto',
    maxHeight: '45px',
    maxWidth: '100%',
    objectFit: 'contain',
    margin: '0 auto',
    '@media (min-width:600px)': {
      maxHeight: '60px'
    },
    '@media (min-width:960px)': {
      maxHeight: '75px'
    }
  },
  imageHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    padding: theme.spacing(1.5),
    textDecoration: 'none',
    '@media (max-width:600px)': {
      padding: theme.spacing(1),
      justifyContent: 'center'
    }
  },
  menu: {
    flexGrow: 1,
    textAlign: 'center'
  },
  button: {
    marginTop: theme.spacing(3),
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  home: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
    lineHeight: theme.typography.h1.lineHeight
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  menuItem: {
    margin: -5
  },
  dropDownLogo: {
    maxHeight: 40
  },
  headerTypo: {
    textAlign: 'left',
    width: 'auto',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(3)
  },
  openRegTitle: {
    marginRight: theme.spacing(2)
  },
  openRegOption: {
    display: "block",
    paddingTop: theme.spacing(.5),
    cursor: "pointer"
  },
  detailType: {
    textAlign: 'right'
  },
  detailValue: {
    textAlign: 'left',
    paddingLeft: theme.spacing(1)
  },
  listItem: {
    width: '70%',
    fontSize: 20,
    paddingTop: theme.spacing(1),
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  listItem100: {
    width: '100%',
    fontSize: 20,
    paddingTop: theme.spacing(1),
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  listItem100Clickable: {
    width: '100%',
    fontSize: 16,
    paddingTop: theme.spacing(1),
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  splitDivisionTeamItem: {
    width: '100%',
    fontSize: 15,
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  splitDivisionDivItem: {
    width: '100%',
    fontSize: 15,
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  splitDivisionUserGridRight: {
    paddingLeft: 15,
    paddingTop: 10
  },
  splitDivisionTeamDropArea: {
    border: "1px solid gray",
    height: "90%",
    borderRadius: theme.spacing(4),
    textAlign: "center"
  },
  listDeleteEdit: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    '@media (max-width:600px)': {
      justifyContent: 'center',
      marginTop: theme.spacing(1)
    }
  },
  programSelect: {
    marginTop: theme.spacing(3)
  },
  fullWidth: {
    width: "100%"
  },
  smallFont: {
    fontSize: 10
  },
  imageHolder: {
    backgroundColor: "rgba(0,0,0,0)"
  },
  brandImageHolder: {
    backgroundColor: "rgba(0,0,0,0)",
    marginLeft: theme.spacing(2)
  },
  brandHolder: {
    marginLeft: theme.spacing(2)
  },
  dateForm: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  fullWidthField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 155
  },
  numField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 55
  },
  tightList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tightListItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tightListItemLink: {
    fontSize: 20,
    paddingTop: theme.spacing(0),
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  grouper: {
    border: '1px solid black',
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    fontSize: '12px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: '100%'
  },
  breadcrumb: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  lastBreadcrumb: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  menuImage: {
    maxHeight: "30px"
  },
  navigationMenu: {
    width: '100%',
    '@media (min-width:960px)': {
      minWidth: "300px"
    }
  },
  gridForm: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    minHeight: 118
  },
  cleanLink: {
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  cardSpacing: {
    margin: theme.spacing(2),
    width: "100%"
  },
  userGrid: {
    padding: theme.spacing(2)
  },
  userGridLeft: {
    borderBottom: "1px solid black",
    borderBottomColor: theme.palette.secondary.main,
    padding: theme.spacing(1)
  },
  userGridRight: {
    borderLeft: "1px solid black",
    borderLeftColor: theme.palette.secondary.main,
    padding: theme.spacing(1)
  },
  userPadding: {
    padding: theme.spacing(1)
  },
  userHeader: {
    borderBottom: "1px solid black",
    borderBottomColor: theme.palette.secondary.main
  },
  permissionGroupTitle: {
    paddingTop: theme.spacing(1)
  },
  permissionGroupBox: {
    padding: 2,
    margin: 2,
    border: "1px solid grey",
    width: "100%",
    display: "grid"
  },
  eventPricingTop: {
    borderBottom: "1px solid black",
    borderBottomColor: theme.palette.secondary.main
  },
  eventPricingHeaders: {
    paddingTop: theme.spacing(2)
  },
  saveIcon: {
    marginRight: theme.spacing(1)
  },
  verbDropdown: {
    border: "1px solid black",
    borderColor: theme.palette.secondary.main
  },
  primaryBackgroundColor: {
    backgroundColor: theme.palette.background.default
  },
  bugReport: {
    position: "fixed",
    bottom: "15px",
    right: "15px"
  },
  eula: {
    maxHeight: 200,
    overflow: 'auto',
    padding: 15,
    marginTop: 20,
    marginBottom: 20
  },
  paymentGridHeader: {
    borderBottom: "1px solid white",
    borderColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(1)
  },
  paymentGridHeaderLeft: {
    marginLeft: 0
  },
  root: {
    flexGrow: 1
  },
  paperGridItem: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    border: 'none',
    boxShadow: 'none'
  },
  personGridRow: {
    borderBottom: "1px solid #545252",
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
      paddingBottom: 0,
      lineHeight: 0.8
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 10,
      paddingBottom: 10,
      lineHeight: 1.2
    }
  },
  personGridItem: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
      paddingBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 10,
      paddingBottom: 10
    }
  },
  personGridHeader: {
    [theme.breakpoints.down('lg')]: {
      lineHeight: 0,
      paddingBottom: 5
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: 1.2,
      paddingBottom: 0
    }
  },
  personGridButton: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      padding: '4px 8px',
      minWidth: '32px',
      height: '32px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '22px'
    }
  },
  formColorOverPrimary: {
    '& input:valid:focus + fieldset, & input:valid:hover + fieldset, & input:valid + fieldset, & input, & label, & label.Mui-focused, & value, & .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, .MuiSvgIcon-root': {
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white
    }
  },
  customMarkdownStyles: {
    "& h1, h2, h3, h4, h5, h6": {
      marginTop: 5,
      marginBottom: 10
    },
    "& p": {
      marginTop: 5,
      marginBottom: 5
    },
    "& img[alt='event-grid-icon']": {
      width: "30px"
    }
  },
  formControl: {
    marginTop: 16,
    marginBottom: 8
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  scheduleSaveButton: {
    backgroundColor: '#b30808',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: "#d32828"
    },
    marginTop: "12px",
    marginLeft: "5px",
    '@media (max-width:600px)': {
      marginTop: "8px",
      marginLeft: "0px",
      width: "100%",
      display: "flex",
      justifyContent: "center"
    }
  },
  grouperForm: {
    border: 'none',
    borderBottom: '1px solid',
    borderColor: theme.palette.secondary.main,
    fontSize: '12px',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  rubricScoring: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  horizontalLine: {
    border: "1px solid black",
    borderColor: theme.palette.secondary.main,
    width: '90%'
  },
  infoIcon: {
    width: '30px',
    cursor: 'pointer'
  },
  textBoxStyle: {
    marginTop: '15px'
  },
  helperText: {
    whiteSpace: 'pre-line',
    fontSize: '12px'
  },
  previewEventScheduleReportHeaderLogo: {
    height: '70px',
    maxWidth: '40%',
    paddingTop: "3px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  eventScheduleReport: {
    padding: "20px"
  },
  eventScheduleReportHeaderSection: {
    background: "black",
    color: "white",
    height: "90px",
    alignItems: "center",
    width: "auto",
    marginBottom: "25px"
  },
  eventScheduleReportPanelDivisions: {
    flexGrow: 1,
    marginTop: "15px",
    marginBottom: "10px",
    width: "101%"
  },
  templateMatrix: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: theme.spacing(1)
  },
  templateMatrixTh: {
    borderBottom: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: theme.typography.h6.lineHeight
  },
  templateMatrixTd: {
    borderBottom: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 16,
    lineHeight: theme.typography.h6.lineHeight
  },
  templateMatrixRadio: {
    cursor: 'pointer',
    transform: 'scale(1.5)'
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  // Responsive container for all nested container layouts
  responsiveContainer: {
    width: '100%',
    padding: theme.spacing(2),
    '@media (max-width:600px)': {
      padding: theme.spacing(1)
    },
    '& .MuiContainer-root': {
      padding: 0,
      '@media (max-width:600px)': {
        padding: 0
      }
    }
  },
  teamContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    flexWrap: 'wrap',
    '@media (max-width:600px)': {
      justifyContent: 'center'
    },
    '& .MuiSvgIcon-root': {
      // Style for the DragIndicatorIcon
      marginRight: '8px'
    },
    '& span': {
      // Style for the text
      flex: 1
    }
  },
  editTeamContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    flexWrap: 'wrap',
    '@media (max-width:600px)': {
      gap: '4px',
      justifyContent: 'center'
    },
    '& .MuiSvgIcon-root': {
      // Style for the DragIndicatorIcon
      marginRight: '8px',
      '@media (max-width:600px)': {
        marginRight: '4px'
      }
    },
    '& .MuiTextField-root': {
      // Style for the TextField
      flex: 1,
      '@media (max-width:600px)': {
        width: '100%',
        marginBottom: '8px'
      }
    },
    '& .MuiButtonBase-root': {
      '@media (max-width:600px)': {
        margin: '4px'
      }
    }
  },
  teamNameField: {
    marginRight: '8px',
    '@media (max-width:600px)': {
      marginRight: '0',
      width: '100%'
    }
  }
}),
// makeStyles options
{
  index: 1
});
export const editorTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000"
    }
  }
});
Object.assign(editorTheme, {
  overrides: {
    MUIRichTextEditor: {
      editor: {
        backgroundColor: "#ebebeb",
        padding: "20px",
        height: "100px",
        maxHeight: "200px",
        overflow: "auto"
      },
      toolbar: {
        display: "none"
      }
    }
  }
});