import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

// Apple-inspired theme with company red as primary color
export const light = createMuiTheme({
  palette: {
    type: "light",
    common: {
      black: '#000000',
      white: '#FFFFFF'
    },
    primary: {
      main: '#D32F2F',
      // Company red as primary color
      light: '#EF5350',
      dark: '#C62828'
    },
    secondary: {
      main: '#000000',
      // Black for text (no gray)
      light: '#212121',
      // Very dark for secondary text
      dark: '#000000' // Black for headers
    },
    error: {
      main: '#D32F2F',
      // Same as primary
      light: '#EF5350',
      dark: '#C62828'
    },
    warning: {
      main: '#ED6C02',
      // Darker orange for better contrast
      light: '#FF9800',
      dark: '#E65100'
    },
    success: {
      main: '#2E7D32',
      // Darker green for better contrast
      light: '#4CAF50',
      dark: '#1B5E20'
    },
    info: {
      main: '#000000',
      // Black instead of gray for info
      light: '#212121',
      dark: '#000000'
    },
    background: {
      default: '#FFFFFF',
      // White background for better contrast
      paper: '#FFFFFF'
    },
    text: {
      primary: '#000000',
      secondary: '#000000'
    },
    divider: 'rgba(0, 0, 0, 0.12)'
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Open Sans',
    h1: {
      fontFamily: 'Open Sans',
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '2rem'
      },
      fontWeight: 'bold'
    },
    h2: {
      fontFamily: 'Open Sans',
      fontSize: '1.2rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem'
      },
      fontWeight: 'bold',
      lineHeight: '2.25rem'
    },
    h3: {
      fontFamily: 'Open Sans',
      fontSize: '0.9rem',
      '@media (min-width:600px)': {
        fontSize: '1rem'
      },
      fontWeight: 'bold',
      lineHeight: '1.75rem',
      display: 'inline-block'
    },
    h4: {
      fontFamily: 'Open Sans',
      fontSize: '.65rem',
      lineHeight: '1rem',
      '@media (min-width:600px)': {
        fontSize: '.75rem'
      },
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    caption: {
      fontFamily: 'Open Sans',
      fontSize: '.65rem',
      '@media (min-width:600px)': {
        fontSize: '.75rem'
      },
      lineHeight: '1rem',
      fontWeight: 'bold',
      color: '#000000'
    }
  }
},
// createMuiTheme options
{
  index: 2
});
export const dark = createMuiTheme({
  palette: {
    type: "dark",
    common: {
      black: '#000000',
      white: '#ffffff'
    },
    primary: {
      main: '#F44336',
      // Brighter red for dark mode with better contrast
      light: '#E57373',
      dark: '#D32F2F'
    },
    secondary: {
      main: '#E0E0E0',
      // Very light gray for text in dark mode
      light: '#FFFFFF',
      dark: '#BDBDBD'
    },
    error: {
      main: '#F44336',
      // Brighter red (same as primary)
      light: '#E57373',
      dark: '#D32F2F'
    },
    warning: {
      main: '#FFA726',
      // Brighter orange for dark mode with better contrast
      light: '#FFB74D',
      dark: '#F57C00'
    },
    success: {
      main: '#66BB6A',
      // Brighter green for dark mode with better contrast
      light: '#81C784',
      dark: '#388E3C'
    },
    info: {
      main: '#FFFFFF',
      // White instead of gray
      light: '#FFFFFF',
      dark: '#E0E0E0'
    },
    background: {
      default: '#121212',
      // Dark background
      paper: '#212121' // Slightly lighter for cards
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF'
    },
    divider: 'rgba(255, 255, 255, 0.2)'
  },
  typography: {
    fontSize: 16,
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1.75rem',
      '@media (min-width:600px)': {
        fontSize: '2.25rem'
      },
      fontWeight: 600,
      letterSpacing: '-0.025em',
      color: '#FFFFFF'
    },
    h2: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '1.75rem'
      },
      fontWeight: 600,
      letterSpacing: '-0.02em',
      lineHeight: 1.2,
      color: '#FFFFFF'
    },
    h3: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1.1rem',
      '@media (min-width:600px)': {
        fontSize: '1.3rem'
      },
      fontWeight: 600,
      letterSpacing: '-0.01em',
      lineHeight: 1.4,
      color: '#FFFFFF'
    },
    h4: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '.9rem',
      '@media (min-width:600px)': {
        fontSize: '1rem'
      },
      lineHeight: 1.5,
      fontWeight: 600,
      color: '#FFFFFF'
    },
    subtitle1: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 500,
      color: '#FFFFFF'
    },
    subtitle2: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '0.875rem',
      lineHeight: 1.5,
      fontWeight: 500,
      color: '#FFFFFF'
    },
    body1: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1rem',
      lineHeight: 1.6,
      fontWeight: 400,
      color: '#FFFFFF'
    },
    body2: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '0.875rem',
      lineHeight: 1.6,
      fontWeight: 400,
      color: '#FFFFFF'
    },
    caption: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '.75rem',
      '@media (min-width:600px)': {
        fontSize: '.8rem'
      },
      lineHeight: 1.5,
      fontWeight: 400,
      color: '#FFFFFF' // White text instead of gray
    },
    button: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      letterSpacing: '0.015em',
      textTransform: 'none'
    }
  }
},
// createMuiTheme options
{
  index: 2
});