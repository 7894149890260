import React, { ReactElement, useMemo, useState } from 'react';
import { AppBar, Grid, Hidden, useMediaQuery, Drawer, IconButton, Select, MenuItem } from '@material-ui/core';
import { useStyles } from "lib/theme";
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProgramPermission, LocationPermission, ProducerPermission, BrandPermission, ProgramPermissions, ProducerPermissions, JudgeEventPermission, JudgeEventPermissions } from "store/user/userReducer";
import HeaderAutoComplete from "./HeaderAutoComplete";
import HeaderBreadcrumbs from "./HeaderBreadcrumbs";
import HeaderMenu from "./HeaderMenu";
import HeaderLogo from "./HeaderLogo";
import { BothSides, logoutUser, refreshUser } from "store/user/userActions";
import { Athlete, Coach, Event, Personnel, Team } from "store/themis_common_pb";
interface HeaderProps {
  programs: ProgramPermissions;
  producers: ProducerPermissions;
  judgeEvents: JudgeEventPermissions;
  isSuperUser: boolean;
  bothSides: BothSides;
  stateEvent: Event.AsObject | undefined;
  stateTeam: Team.AsObject | undefined;
  stateAthlete: Athlete.AsObject | undefined;
  stateCoach: Coach.AsObject | undefined;
  statePersonnel: Personnel.AsObject | undefined;
  userState: string;
  setUserState: Function;
  username: string;
  loggedIn: boolean;
  tryLogin: boolean;
  currentBrand: BrandPermission | undefined;
  currentLocation: LocationPermission | undefined;
  currentProducer: ProducerPermission | undefined;
  setCurrentProducer: Function;
  currentProgram: ProgramPermission | undefined;
  setCurrentProgram: Function;
  currentEventRegistrationLogo: string | undefined;
  currentJudgeEvent: JudgeEventPermission | undefined;
  setCurrentJudgeEvent: Function;
}
const Header: React.FC<HeaderProps> = ({
  programs,
  producers,
  judgeEvents,
  isSuperUser,
  bothSides,
  username,
  loggedIn,
  tryLogin,
  stateTeam,
  stateAthlete,
  stateEvent,
  stateCoach,
  statePersonnel,
  userState,
  setUserState,
  currentBrand,
  currentLocation,
  currentProducer,
  setCurrentProducer,
  currentProgram,
  setCurrentProgram,
  currentEventRegistrationLogo,
  currentJudgeEvent,
  setCurrentJudgeEvent
}): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleLogout = (dispatch: any) => {
    return () => {
      const doLogout = logoutUser();
      setCurrentProgram(undefined);
      setCurrentProducer(undefined);
      doLogout(dispatch);
    };
  };
  const currentFocus = useMemo(() => {
    // This sets the correct permissions in focus for generating the header autocomplete
    if (userState === 'program') {
      return currentProgram;
    } else if (userState === 'producer') {
      return currentProducer;
    } else if (userState === 'judge') {
      return {
        id: currentJudgeEvent?.id,
        name: `${currentJudgeEvent?.brandName || ''} - ${currentJudgeEvent?.name || ''}`,
        eventId: currentJudgeEvent?.id,
        eventName: currentJudgeEvent?.name || ''
        // Add any other relevant properties needed for focus
      };
    }
  }, [userState, currentProducer, currentProgram, currentJudgeEvent]);
  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  // For checking if user is impersonated - we don't want to expose this in the standard UI
  const oldUsername = null;

  // Helper to create button styles
  const createMenuButtonStyle = (isPrimary = false) => ({
    textDecoration: 'none',
    display: 'block',
    padding: '12px',
    backgroundColor: isPrimary ? '#B30808' : '#952222',
    // Darker red shades
    color: 'white',
    borderRadius: '4px',
    marginBottom: '10px',
    fontWeight: 'bold',
    textAlign: 'center'
  });
  const mobileDrawer = <Drawer anchor="right" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
      <div style={{
      width: 275,
      padding: theme.spacing(2),
      backgroundColor: '#1a1a1a'
    }}>
        {/* User identification */}
        <div style={{
        padding: '15px 10px',
        borderBottom: '1px solid #333',
        marginBottom: '15px',
        fontWeight: 'bold',
        fontSize: '18px',
        textAlign: 'center',
        color: 'white'
      }}>
          {username}
        </div>
        
        {/* Direct action buttons */}
        <RouterLink to="/Account" style={createMenuButtonStyle(true)} onClick={() => setMobileMenuOpen(false)}>
          My Account
        </RouterLink>
        
        {(Object.keys(programs).length > 0 || Object.keys(producers).length > 0) && <RouterLink to="/ManagePaymentMethods" style={createMenuButtonStyle()} onClick={() => setMobileMenuOpen(false)}>
            Payment Methods
          </RouterLink>}
        
        {/* Switch between program, producer, judge options */}
        {(bothSides.program || bothSides.producer || bothSides.judge) && <div style={{
        marginBottom: '15px'
      }}>
            <div style={{
          marginBottom: '8px',
          fontWeight: 'bold',
          paddingLeft: '4px',
          color: 'white'
        }}>Account Type:</div>
            <Select style={{
          width: "100%",
          backgroundColor: '#7A1C1C',
          // Darker, less intense red
          color: 'white',
          borderRadius: '4px',
          marginBottom: '5px'
        }} variant="outlined" id="mobile-userType-select" value={userState} onChange={e => {
          const newState = String(e.target.value);
          setUserState(newState);
          localStorage.setItem('programOrProducer', newState);
          refreshUser(dispatch);
          setMobileMenuOpen(false);
          history.push('/');
        }}>
              {bothSides.judge && <MenuItem value='judge'>Judge</MenuItem>}
              {bothSides.program && <MenuItem value='program'>Program</MenuItem>}
              {bothSides.producer && <MenuItem value='producer'>Producer</MenuItem>}
            </Select>
          </div>}
        
        {/* Access code options */}
        <RouterLink to="/ShareLogin" style={createMenuButtonStyle()} onClick={() => setMobileMenuOpen(false)}>
          Generate Access Code
        </RouterLink>
        
        <RouterLink to="/AccessCodeLogin" style={createMenuButtonStyle()} onClick={() => setMobileMenuOpen(false)}>
          Use Access Code
        </RouterLink>
        
        {/* Refresh option */}
        <div onClick={() => {
        refreshUser(dispatch);
        setMobileMenuOpen(false);
      }} style={{
        ...createMenuButtonStyle(),
        cursor: 'pointer'
      }}>
          Refresh Login
        </div>
        
        {/* Logout option */}
        <div onClick={() => {
        handleLogout(dispatch)();
        setMobileMenuOpen(false);
        history.replace("/Login");
      }} style={{
        ...createMenuButtonStyle(),
        cursor: 'pointer'
      }}>
          {oldUsername ? `Switch Back to ${oldUsername}` : "Logout"}
        </div>
      </div>
    </Drawer>;
  return <AppBar color="transparent" position="sticky" elevation={0} className={classes.headerRoot}>
      <Grid container alignItems="center">
        {/* Desktop layout */}
        <Hidden smDown>
          {/* Logo next to navigation */}
          <Grid item md={3}>
            <HeaderLogo userState={userState} currentFocus={currentFocus} currentBrand={currentBrand} currentEventRegistrationLogo={currentEventRegistrationLogo} judgeEvents={judgeEvents} />
          </Grid>
          
          <Grid item container md={7}>
            <HeaderAutoComplete programs={programs} producers={producers} userState={userState} isSuperUser={isSuperUser} bothSides={bothSides} judgeEvents={judgeEvents} />
            <Grid item container xs={12}>
              <HeaderBreadcrumbs currentProducer={currentProducer} currentBrand={currentBrand} currentProgram={currentProgram} currentLocation={currentLocation} stateEvent={stateEvent} stateTeam={stateTeam} stateAthlete={stateAthlete} stateCoach={stateCoach} statePersonnel={statePersonnel} userState={userState} producers={producers} programs={programs} judgeEvents={judgeEvents} />
            </Grid>
          </Grid>
          <Grid item container md={2}>
            <HeaderMenu bothSides={bothSides} username={username || ""} setCurrentProducer={setCurrentProducer} setCurrentProgram={setCurrentProgram} loggedIn={loggedIn} tryLogin={tryLogin} userState={userState} setUserState={setUserState} />
          </Grid>
        </Hidden>

        {/* Mobile layout */}
        <Hidden mdUp>
          {/* Logo on mobile */}
          <Grid item xs={8}>
            <HeaderLogo userState={userState} currentFocus={currentFocus} currentBrand={currentBrand} currentEventRegistrationLogo={currentEventRegistrationLogo} judgeEvents={judgeEvents} />
          </Grid>
          
          {/* Mobile hamburger menu */}
          <Grid item container xs={4} justify="flex-end" alignItems="center">
            <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleMobileMenuToggle}>
              <MenuIcon />
            </IconButton>
          </Grid>
          {mobileDrawer}
          
          {/* Mobile autocomplete below header */}
          <Grid item xs={12}>
            <HeaderAutoComplete programs={programs} producers={producers} userState={userState} isSuperUser={isSuperUser} bothSides={bothSides} judgeEvents={judgeEvents} isMobile={true} />
          </Grid>
        </Hidden>
      </Grid>
    </AppBar>;
};
export default Header;