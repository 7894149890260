import { ACCESS_TOKEN, REFRESH_TOKEN } from "lib/constants";
import { ThemisApiPromiseClient } from "./themis_api_grpc_web_pb";
import environment from "environment";
import { refreshUser } from "./user/userActions";

// Cache to store recent requests and their responses
const requestCache = new Map<string, { timestamp: number; response: any }>();
const CACHE_DURATION = 300; // 10 seconds in milliseconds

const handleLogout = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem('SESSION_ID');
  localStorage.removeItem("CURRENT_EVENT");
  localStorage.removeItem("CURRENT_TEAM");
  localStorage.removeItem('producerConnectLink');
  window.location.href = "/";
};

let loadingTimer;

// Add a method to the protobuf request objects
// Note: This is a runtime extension, not a TypeScript type extension
Object.defineProperty(Object.prototype, 'excludeFields', {
  value: function(fields: string[]) {
    this._excludeFields = fields;
    return this;
  },
  configurable: true,
  writable: true
});

class AuthInterceptor {
  private initialized = false;

  async intercept(request: any, invoker: any) {
    const sessionId = localStorage.getItem('SESSION_ID');
    const metadata = request.getMetadata() || {};
    let token = localStorage.getItem(ACCESS_TOKEN);
    
    // Special cases that always use JWT
    const useJWTEndpoints: string[] = [
      // "/themis.ThemisApi/Refresh",
      // "/themis.ThemisApi/UserAuth"
    ];
    const useJWT = useJWTEndpoints.includes(request.c.name);

    // Check if this is a public route that doesn't need authentication
    const url = window.location.pathname;
    const isPublicScoreSheet = url.match(/^\/Location\/score_sheet\/event\/\d+\/team\/\d+/i) !== null;

    // Skip authentication for public score sheet routes
    if (isPublicScoreSheet) {
      // For public routes, we don't need to add any auth headers
      const { store } = await import('../store/store');
      store.dispatch({ type: 'INCREMENT_REQUEST_COUNT' });
      
      try {
        const response = await invoker(request);
        store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });
        return response;
      } catch (error) {
        store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });
        throw error;
      }
    }

    // For all other routes, continue with normal authentication
    // After initialization, use existing session if available
    if (sessionId) {
      metadata['session-id'] = sessionId;
      // Clear JWT header when using session
      delete metadata.Authorization;
    } else if (token) {
      metadata.Authorization = 'Bearer ' + token;
    }

    const { store } = await import('../store/store');
    store.dispatch({ type: 'INCREMENT_REQUEST_COUNT' });

    try {
      const response = await invoker(request);
      store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });
      if (response?.metadata?.['session-id'].length > 0) {
        console.log('session-id', response?.metadata?.['session-id']);
      // Always update session if server provides a new one
      const newSessionId = response?.metadata?.['session-id'];
      if (newSessionId) {
        localStorage.setItem('SESSION_ID', newSessionId);
      }
    }
      return response;

    } catch (error) {
      store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });
      
      // Check for authentication errors
      if ((error && typeof error === 'object' && 'code' in error && (error.code === 16 || error.code === 2)) && 
          (typeof error === 'object' && 'message' in error && typeof error.message === 'string' && error.message.indexOf('no valid authentication found') !== -1)) {
        
        // Only attempt refresh if not already doing a refresh request
        if (!request.c.name.includes('Refresh')) {
          try {
            await refreshUser();
            const token = localStorage.getItem(ACCESS_TOKEN);
            const currentSessionId = localStorage.getItem('SESSION_ID');
            
            if (currentSessionId) {
              metadata['session-id'] = currentSessionId;
              delete metadata.Authorization;
            } else if (token) {
              metadata.Authorization = 'Bearer ' + token;
            }
            return invoker(request);
          } catch (refreshError) {
            // If refresh fails, log out
            handleLogout();
            throw refreshError;
          }
        } else {
          // If refresh itself fails, log out
          handleLogout();
          throw error;
        }
      }
      throw error;
    }
  }
}

const authInterceptor = new AuthInterceptor();

const clientOptions = {
  unaryInterceptors: [authInterceptor],
  streamInterceptors: []
};

export const client = new ThemisApiPromiseClient(environment.BASE_URL, null, clientOptions);