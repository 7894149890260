import React, { ReactElement, useEffect } from 'react';
import './App.css';
import 'react-dropzone-uploader/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { IAppState } from "store/store";
import { selectCurrentCategories, selectCurrentPermissionLists, selectCurrentLoginState, selectTryLogin, selectPermissionCodes, selectUserPrograms } from "store/user/userSelectors";
import { refreshUser, getCategories, getPermissions, getPermissionCodes, getUserGeolocation, tryLogin } from "store/user/userActions";
import HeaderContainer from "components/header/HeaderContainer";
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { ProgramPermissions } from "store/user/userReducer";
import { connectProgramToProducerLink } from 'store/producer/producerActions';
import NotificationSnackbar from 'components/util/NotificationSnackbar';
import 'global.css';
const App: React.FC = (): ReactElement => {
  const loggedIn = useSelector((state: IAppState) => selectCurrentLoginState(state));
  const tryLoginState = useSelector((state: IAppState) => selectTryLogin(state));
  const categories = useSelector((state: IAppState) => selectCurrentCategories(state));
  const permissionLists = useSelector((state: IAppState) => selectCurrentPermissionLists(state));
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state));
  const programs: ProgramPermissions = useSelector((state: IAppState) => selectUserPrograms(state));
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [canSetConnectLink, setCanSetConnectLink] = useState<boolean>(true);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [noHeader, setNoHeader] = useState(false);
  const connectionLinkRedirect = history.location.pathname;
  useEffect(() => {
    const checkLogin = async () => {
      if (!loggedIn) {
        // Try to log in
        await refreshUser(dispatch);
        if (dispatch) tryLogin(dispatch, false);
      }
    };
    checkLogin();
  }, [loggedIn, dispatch]);
  useEffect(() => {
    if (!loggedIn) return;
    if (categories && categories?.allStarTypes.length === 0 && categories?.divisions.length === 0 && categories?.tiers.length === 0 && categories?.scoringAuthorities.length === 0) {
      getCategories(dispatch);
    }
  }, [dispatch, categories, loggedIn]);
  useEffect(() => {
    if (!loggedIn) return;
    if (permissionLists && !permissionLists.program.length) {
      getPermissions(dispatch);
    }
  }, [dispatch, permissionLists, loggedIn]);
  useEffect(() => {
    if (!loggedIn) return;
    if (permissionCodes && !permissionCodes.denyAccess.length) {
      getPermissionCodes(dispatch);
    }
  }, [dispatch, permissionCodes, loggedIn]);
  useEffect(() => {
    // If not logged in or registered from connect link, save connect link in localStorage
    if (!loggedIn && canSetConnectLink) {
      localStorage.setItem('producerConnectLink', location.pathname.toLowerCase());
      setCanSetConnectLink(false);
    }
  }, [location.pathname, loggedIn, canSetConnectLink, history, programs]);

  // If logged in with producer connect link and user has one program, connect that program to the producer
  useEffect(() => {
    const storageProducerLink = localStorage.getItem('producerConnectLink');
    // TODO: Find a better way to check for storageProducerLink? Don't like this includes method but works for now.
    if (loggedIn && storageProducerLink?.includes('/connect/') && Object.entries(programs).length === 1) {
      const program = Object.values(programs)[0];
      connectProgramToProducerLink(program.id);
      setIsNotificationOpen(true);
    }
  }, [loggedIn, programs]);
  useEffect(() => {
    const publicRoutes: string[] = ["login", "register", "eventproducers", "documentsign", "passwordreset", "schedulereport", "eventdivision", "publicscoresheet"];
    const noHeaderRoutes: string[] = ["teamreport", "shiftreport", "score_sheet"];
    const rootPath = location.pathname.split('/')[1].toLowerCase();

    // Check if this is the public score sheet route
    const isPublicScoreSheet = location.pathname.match(/^\/Location\/score_sheet\/event\/\d+\/team\/\d+/i) !== null;
    if (!loggedIn && !tryLoginState && !publicRoutes.includes(rootPath) && !isPublicScoreSheet) {
      const returnURLParam = "returnURL=";
      const loginRedirectPath = "/Login";
      const connectLink = returnURLParam + connectionLinkRedirect;
      localStorage.setItem("connectionLink", connectionLinkRedirect);
      history.push({
        pathname: loginRedirectPath,
        search: connectLink
      });
    }
    setNoHeader(noHeaderRoutes.includes(rootPath) || location.pathname.toLowerCase().includes('/score_sheet'));
  }, [loggedIn, tryLoginState, history, location.pathname]);
  useEffect(() => {
    if (!loggedIn) return;
    if (navigator.geolocation) {
      getUserGeolocation((position: GeolocationPosition) => {
        return;
      });
    }
  }, [dispatch, permissionCodes, loggedIn]);
  const handleNotificationClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setIsNotificationOpen(false);
  };
  return permissionCodes && permissionCodes.denyAccess.length && !noHeader ? <div className="App">
        <HeaderContainer />
        <NotificationSnackbar isOpen={isNotificationOpen} onClose={handleNotificationClose} message="Your program was connected to the producer." severity="success" autoHideDuration={5000} />
      </div> : <></>;
};
export default App;